<template>
  <PanelSection title="Importar claves" :hide-back-button="false">
    <div class="company-keys-form-import">
      <v-container>
        <CustomCard>
          <CustomStepper :items="stepperItems" @onFinish="handleFinish" />
        </CustomCard>
      </v-container>
    </div>
  </PanelSection>
</template>

<script>
// Components
import CustomCard from '@/components/ui/CustomCard'
import PanelSection from '@/components/ui/PanelSection'
import CustomStepper from '@/components/ui/CustomStepper'
import CompanyKeysFormStepper1 from '@/components/elements/companyKeys/CompanyKeysFormStepper1'
import CompanyKeysFormStepper2 from '@/components/elements/companyKeys/CompanyKeysFormStepper2'
import CompanyKeysFormStepper3 from '@/components/elements/companyKeys/CompanyKeysFormStepper3'
// Mixins
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'CompanyKeysImportForm',
  components: {
    CustomCard,
    PanelSection,
    CustomStepper
  },
  mixins: [uiMixin],
  data() {
    return {
      // Diferent sections (items) inside "stepper"
      stepperItems: [
        {
          label: 'Fichero',
          component: CompanyKeysFormStepper1
        },
        {
          label: 'Columnas',
          component: CompanyKeysFormStepper2
        },
        {
          label: 'Fin proceso',
          component: CompanyKeysFormStepper3
        }
      ]
    }
  },
  methods: {
    /**
     * Handle event when the stepper is finished
     *
     * @param {array} data - every data collected inside stepper
     */
    handleFinish() {
      // TODO
    }
  }
}
</script>
