<template>
  <div class="companykeys-form-stepper-3">
    <div class="text-subtitle-1 mb-4">
      Con el fichero subido y las columnas seleccionadas, solo necesita pulsar
      sobre "Importar registros" para comenzar el proceso de importación, si no
      está seguro de ello puede siempre volver a atraś y cambiar tanto el
      fichero indicado como la columnas relacionadas a los datos.
    </div>
    <!-- action buttons -->
    <v-card-actions>
      <FormButtons
        align-buttons="space-between"
        accept-button-text="Importar registros"
        :accept-button-loading="importationProcess"
        cancel-button-text="< Anterior"
        @onClickAcceptButton="handleClickAcceptButton"
        @onClickCancelButton="handleClickCancelButton"
      />
    </v-card-actions>
  </div>
</template>

<script>
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { createCompanyKeysFromArray } from '@/services/companyKeys'

export default {
  name: 'CompanyKeysFormStepper3',
  components: { FormButtons },
  mixins: [uiMixin],
  props: {
    // Data from parent "stepper"
    itemsData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      importationProcess: false
    }
  },
  methods: {
    /**
     * When click on Accept button (finish processing)
     */
    async handleClickAcceptButton() {
      this.importationProcess = true
      try {
        // Parse data
        const data = this.parseDataToImport()

        // Import data
        await createCompanyKeysFromArray(data)
        // show info
        this.modifyAppAlert({
          text: 'Se importaron correctamente todos los registros',
          visible: true
        })
        // Reset "stepper" data
        this.$parent.$parent.$parent.$emit('onResetItemsData')
      } catch (error) {
        // show error
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.importationProcess = false
      }
    },
    /**
     * When click on Cancel button (previous button)
     */
    handleClickCancelButton() {
      this.$parent.$parent.$parent.$emit('onChangeStep', 2)
    },
    /**
     * Parse data before import registers
     *
     * @return {array} - parsed data to import
     */
    parseDataToImport() {
      // Parsing data to import
      const { csv } = this.itemsData[1]
      const { selectedColumns } = this.itemsData[2]

      return csv.reduce((sumItems, item) => {
        // Parse each row
        const itemData = Object.entries(selectedColumns).reduce(
          (sumColumns, column) => {
            if (item[column[0]]) {
              sumColumns[column[1].field] = item[column[0]]
            }
            return sumColumns
          },
          {}
        )

        // Add in main data array
        if (
          typeof itemData === 'object' &&
          itemData !== null &&
          Object.keys(itemData).length
        ) {
          sumItems.push(itemData)
        }

        return sumItems
      }, [])
    }
  }
}
</script>
