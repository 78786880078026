<template>
  <div class="companykeys-form-stepper-1">
    <v-form novalidate @submit.prevent="onSubmit">
      <!-- file -->
      <v-file-input
        v-model="$v.formFields.file.$model"
        label="CSV archivo"
        :error-messages="checkFieldErrors('file')"
        @change="touchField('file')"
      />
      <!-- action buttons -->
      <v-card-actions>
        <FormButtons
          align-buttons="space-between"
          accept-button-text="Siguiente >"
          :accept-button-loading="formProcessing"
          cancel-button-hide
        />
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
// Utils
import { fileAsText, getDataFromCSV } from '@/utils'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CompanyKeysFormStepper1',
  components: { FormButtons },
  mixins: [formMixin, validationMixin],
  props: {
    // Data from parent "stepper"
    itemsData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    // Form
    return {
      formFields: {
        file: null
      },
      formFieldsValidations: {
        file: {
          required: 'Campo obligatorio'
        }
      }
    }
  },
  methods: {
    /**
     * Is triggered after the form is correctly
     * validated by "Vue-validate"
     */
    async afterSubmit() {
      await fileAsText(
        this.formFields.file,
        this.successCSVFile,
        this.errorCSVFile
      )
      this.formProcessing = false
    },
    /**
     * Handle success CSV file
     *
     * @param {object} event - event action
     */
    successCSVFile(event) {
      const csv = event.target.result
      const data = getDataFromCSV(csv)

      this.$parent.$parent.$parent.$emit('onChangeItemsData', 1, { csv: data })
      this.$parent.$parent.$parent.$emit('onChangeStep', 2)
    },
    /**
     * Handle error CSV file
     *
     * @param {object} event - event action
     */
    errorCSVFile(event) {
      if (event.target.error.name === 'NotReadableError') {
        // show error
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: 'Hubo un error intentando leer el fichero',
          type: 'error',
          visible: true
        })
      }
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      file: {
        required
      }
    }
  }
}
</script>
