<template>
  <div class="companykeys-form-stepper-2">
    <v-form novalidate @submit.prevent="onSubmit">
      <template v-if="getColumnsNumberCSV > 0">
        <div class="text-subtitle-1 mb-4">
          Relaciona las columnas con los datos del fichero
        </div>
        <!-- columns -->
        <div class="columns">
          <div v-for="n in getColumnsNumberCSV" :key="n" class="item">
            <!-- Selector -->
            <v-select
              value=""
              :items="columnsToMatch"
              item-value="id"
              item-text="label"
              outlined
              dense
              color="grey"
              :hide-details="!checkFieldErrors('selectedColumns').length"
              :error-messages="checkFieldErrors('selectedColumns')"
              @change="handleChangeColumn($event, n - 1)"
            />
            <!-- CSV data -->
            <ul class="data">
              <li
                v-for="(content, contentIndex) in getParsedCSVContent[n - 1]"
                :key="`${n}${contentIndex}`"
              >
                {{ content }}
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-subtitle-1 error--text mb-4">
          No se ha encontrado ningún dato en el fichero indicado. Pulse en
          "Anterior" e indique otro fichero.
        </div>
      </template>
      <!-- action buttons -->
      <v-card-actions>
        <FormButtons
          align-buttons="space-between"
          accept-button-text="Siguiente >"
          :accept-button-disabled="getColumnsNumberCSV === 0"
          :accept-button-loading="formProcessing"
          cancel-button-text="< Anterior"
          @onClickCancelButton="handleClickCancelButton"
        />
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
// Utils
import { cloneDeep } from 'lodash'

export default {
  name: 'CompanyKeysFormStepper2',
  components: { FormButtons },
  mixins: [formMixin, validationMixin],
  props: {
    // Data from parent "stepper"
    itemsData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    // Form
    return {
      formFields: {
        selectedColumns: {}
      },
      formFieldsValidations: {
        selectedColumns: {
          mustSelectAtLeastId:
            'Debe seleccionar al menos una columna como "identificador"'
        }
      },
      // Columns to match with data
      columnsToMatch: [
        {
          id: '',
          label: 'No usar'
        },
        {
          id: 'id',
          label: 'Identificador'
        },
        {
          id: 'area',
          label: 'Área del trader'
        },
        {
          id: 'companyName',
          label: 'Nombre compañía'
        }
      ]
    }
  },
  computed: {
    /**
     * Get the content (first 5 items) of CSV file from "first step"
     *
     * @return {array} - csv data
     */
    getParsedCSVContent() {
      if (
        this.itemsData &&
        this.itemsData[1] &&
        Array.isArray(this.itemsData[1].csv)
      ) {
        const exampleContent = []
        let i = 0

        do {
          for (let j = 0; j < this.itemsData[1].csv[i].length; j += 1) {
            if (exampleContent[j] && exampleContent[j].length) {
              exampleContent[j] = [
                ...exampleContent[j],
                this.itemsData[1].csv[i][j]
              ]
            } else {
              exampleContent[j] = [this.itemsData[1].csv[i][j]]
            }
          }
          i += 1
        } while (i < 5 && this.itemsData[1].csv[i])

        return exampleContent
      }

      return []
    },
    /**
     * Get the number columns of CSV file from "first step"
     *
     * @return {array} - csv data
     */
    getColumnsNumberCSV() {
      return this.itemsData &&
        this.itemsData[1] &&
        this.itemsData[1].csv &&
        this.itemsData[1].csv[0]
        ? this.itemsData[1].csv[0].length
        : 0
    }
  },
  methods: {
    /**
     * Handle when the user matchs one column with a id
     *
     * @param {string} value - column value
     * @param {string} index - index inside selected column
     */
    handleChangeColumn(value, index) {
      // It is necessary (cloneDeep) because "vuelidate" can't see the changes
      this.formFields.selectedColumns = cloneDeep(
        this.formFields.selectedColumns
      )

      // Touch to check with Vuevalidate
      this.formFields.selectedColumns[index] = {
        field: value
      }
      this.touchField('selectedColumns')
    },
    /**
     * When click on Cancel button (previous button)
     */
    handleClickCancelButton() {
      this.$parent.$parent.$parent.$emit('onChangeStep', 1)
    },
    /**
     * Is triggered after the form is correctly
     * validated by "Vue-validate"
     */
    async afterSubmit() {
      // Add data to parent "stepper"
      this.$parent.$parent.$parent.$emit('onChangeItemsData', 2, {
        selectedColumns: this.formFields.selectedColumns
      })
      // Pass the next step
      this.$parent.$parent.$parent.$emit('onChangeStep', 3)
      // Hide loading
      this.formProcessing = false
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      selectedColumns: {
        mustSelectAtLeastId: async value => {
          return Object.entries(value).find(field => {
            return field[1].field === 'id'
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/theme/variables.scss';
.columns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .item {
    margin: 0 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    .data {
      margin: 0.5rem 0 0 0;
      padding: 0;
      list-style: none;
      background-color: $theme_background;
      border: 1px solid $theme-border-color;
      border-radius: 4px;
      li {
        font-size: 0.75rem;
        padding: 0.25rem 0.5rem;
        border-bottom: 1px solid $theme-border-color;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
